import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { HistoryDetailEvent } from './EventPage'

const containerStyle = {
    width: '1110px',
    height: '600px'
};

const PAGE_SIZE = 20;

export class PlayPage extends Component {
    constructor(props) {
        super(props);
        this.state = { historyEvents: [], playIndex: 0, isPlaying: false, isPaused: false, startYear: 1500, pageIndex: 0 };
        this.handleClick = this.handleClick.bind(this);
        this.playNext = this.playNext.bind(this);
        this.handlePauseClick = this.handlePauseClick.bind(this);
        this.handlePreviousClick = this.handlePreviousClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }
    static timer;
    handleClick() {
        if (this.state.isPlaying) {
            clearInterval(this.timer);
            this.setState({ isPlaying: false });
        }
        else {
            this.setState({ isPlaying: true, pageIndex: 0 });
            this.populateEventData(this.state.startYear, PAGE_SIZE, this.state.pageIndex, 0);
        }
    }

    handlePauseClick() {
        if (!this.state.isPlaying) {
            return;
        }

        clearInterval(this.timer);
        if (this.state.isPaused) {
            this.timer = setInterval(() => { this.playNext() }, 5000)
            this.setState({ isPaused: false });
        } else {
            this.setState({ isPaused: true });
        }
    }

    async populateEventData(fyear, pagesize, pageindex, playerindex) {
        const response = await fetch(`api/historyevent/date?fyear=${fyear}&pagesize=${pagesize}&pageindex=${pageindex}`);
        const data = await response.json();
        this.setState({ historyEvents: data, isPlaying: true, playIndex: playerindex });
        clearInterval(this.timer);
        this.timer = setInterval(() => { this.playNext() }, 5000)
    }

    playNext() {
        var nextPlayIndex = this.state.playIndex + 1;
        if (nextPlayIndex < PAGE_SIZE && nextPlayIndex < this.state.historyEvents.length) {
            this.setState({ playIndex: nextPlayIndex })
        } else {
            this.setState({ pageIndex: this.state.pageIndex + 1 })
            this.populateEventData(this.state.startYear, PAGE_SIZE, this.state.pageIndex, 0);
        }
    }

    playPrevious() {
        var nextPlayIndex = this.state.playIndex - 1;
        if (nextPlayIndex >= 0 && this.state.historyEvents.length > 0) {
            this.setState({ playIndex: nextPlayIndex })
        } else {
            if (this.state.pageIndex == 0) {
                return;
            }

            if (nextPlayIndex == 0 && this.state.pageIndex > 0) {
                this.setState({ pageIndex: this.state.pageIndex - 1 })
                this.populateEventData(this.state.startYear, PAGE_SIZE, this.state.pageInde, PAGE_SIZE - 1);
            }
        }
    }

    handlePreviousClick() {
        this.playPrevious();
    }

    handleNextClick() {
        this.playNext();
    }
    render() {
        var center = { lat: 0, lng: 0 };
        if (this.state.isPlaying && this.state.historyEvents.length > 0) {
            center = { lat: this.state.historyEvents[this.state.playIndex].lat, lng: this.state.historyEvents[this.state.playIndex].lng }
        }
        return (
            <div>
                <div>Start Year: <input
                    type="text"
                    value={this.state.startYear}
                    onChange={e => this.setState({ startYear: e.target.value })}
                />
                    <button onClick={this.handleClick}>
                        {this.state.isPlaying ? 'STOP' : 'START'}
                    </button>
                    { this.state.isPlaying &&
                        <button onClick={this.handlePauseClick}>
                            {this.state.isPaused ? 'RESUME' : 'PAUSE'}
                        </button>
                    }
                    {this.state.isPaused &&
                        <div>
                        <button onClick={this.handlePreviousClick}>&lt;
                        </button>
                        <button onClick={this.handleNextClick}>&gt;
                        </button></div>
                    }
                </div>
                <LoadScript
                    googleMapsApiKey="AIzaSyC5A2D9YGo2XrLDTj9i2PegVo5qGTYDkeo"
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={5}
                    >
                        <Marker position={center} />
                    </GoogleMap>
                </LoadScript>

                {this.state.isPlaying && this.state.historyEvents.length > 0 &&
                        <HistoryDetailEvent he={this.state.historyEvents[this.state.playIndex]} />
                }

                
            </div>
        );
    }
}
import React from 'react';
import { Marker, Polyline } from '@react-google-maps/api';

const defaulticon = "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red.png"
const selectedicon = "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_purple.png"
export const Markers = ({ events, onEventClicked, selectedEvent }) => {
    return events.map(hevent => {
        const handleClick = () => {
            onEventClicked(hevent);
        };

        var icon = defaulticon;
        var zindex = 1;
        if (selectedEvent != null && hevent.uuid == selectedEvent.uuid) {
            icon = selectedicon;
            zindex = 999;
        }
        return (
            <Marker position={{ lat: hevent.lat, lng: hevent.lng }} onClick={handleClick} key={hevent.uuid} icon={icon} zIndex={zindex} />
        );
    });
};

const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths: [
    ],
    zIndex: 1
};

export const EventPolyline = ({ events }) => {
    const path = []
    events.forEach((event) => { path.push({ lat: event.lat, lng: event.lng }) });

    options.paths = path;
    return (
        <Polyline path={path}
            options={options} />
        );
};
import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { EventPage } from './components/EventPage'
import { PersonPage } from './components/PersonPage'
import { PlayPage } from './components/Play'

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/play' component={PlayPage} />
            <Route path='/events/:uuid' component={EventPage} />
            <Route path='/people/:uuid' component={PersonPage} />
      </Layout>
    );
  }
}

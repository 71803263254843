import React, { Component } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { YearSlide } from './SliderStyle'
import { HistoryEvent } from './EventPage'
import { Markers } from './Marker'

import Grid from '@mui/material/Grid';
import './NavMenu.css';

const containerStyle = {
    height: '800px'
};

const divStyle = {
    padding: "0 50px 0 50px"
}

const initialCenter = {
    lat: 50.745,
    lng: -3.523
};

/*
const timeMaps = [{ title: 'Glorious Revolution', startYear: 1687, endYear: 1690, center: { lat: 52.7078296, lng: -1.3138272 }, zoom: 7},
    { title: 'French Revolution', startYear: 1788, endYear: 1800, center: { lat: 46.821222, lng: 4.7497835 }, zoom: 7 },
    { title: 'The Revolutionary War', startYear: 1775, endYear: 1783, center: { lat: 40.6895582, lng: -75.5352849}, zoom: 8 }];
*/

export const QuickMap = ({ timeMaps, setCenter, setyear }) => {

    const handleEventClicked = (tm) => {
        setCenter({lat: tm.lat,lng: tm.lng }, tm.zoom);
        setyear([tm.startYear, tm.endYear]);
    };

    return (<div class="quick-navigation">
        <h2 class="quick-navigation-title">Quick Navigation</h2>
        <div class="button-container">{
        timeMaps.map(tm => {

            return (
                <button class="round-button" onClick={() => handleEventClicked(tm)}> {tm.title} </button>
            );
        })
    } </div></div>);
};

export class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props);

        this.state = { markerArray: [], selectedEvent: null, yearRange: [1600, 1730], center: initialCenter, zoom: 9, timeMaps: [], };
        this.boundsChanged = this.boundsChanged.bind(this);
        this.handleEventClicked = this.handleEventClicked.bind(this);
        this.updateYearRange = this.updateYearRange.bind(this);
        this.changeCenter = this.changeCenter.bind(this);
        this.highlightedCardRef = React.createRef();
    }

    updateYearRange(yr) {
        this.setState({ yearRange: yr });
        this.boundsChanged(null);
    }

    handleEventClicked(hevent) {
        this.setState({ selectedEvent: hevent }, () => {
            if (this.highlightedCardRef.current) {
                this.highlightedCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        });
    }

    componentDidMount() {
        //this.populateEventData();
        fetch('api/NavigateEvent')
            .then(response => response.json())
            .then(data => {
                this.setState({ timeMaps: data });
            })
            .catch(error => {
                console.error('Error fetching configuration:', error);
            });
    }

    async populateEventData(ltlat, ltlng, rblat, rblng, fyear, tyear) {
        const response = await fetch(`api/historyevent?ltlat=${ltlat}&ltlng=${ltlng}&rblat=${rblat}&rblng=${rblng}&fyear=${fyear}&tyear=${tyear}`);
        const data = await response.json();
        this.setState({ markerArray: data});
    }

    changeCenter = (newCenter, nz) => {
        this.setState({ center: newCenter, zoom: nz });
        if (this._map.current) {
            this._map.current.panTo(newCenter);
        }
    };

  render () {
      return (
          <div className="outer">
              <div>
                  <YearSlide values={this.state.yearRange} setValues={this.updateYearRange} />
              </div>
              <Grid container spacing={0} >
                  <Grid item xs>
                      <LoadScript
                          googleMapsApiKey="AIzaSyC5A2D9YGo2XrLDTj9i2PegVo5qGTYDkeo"
                      >
                          <GoogleMap
                              ref={(map) => this._map = map}
                              mapContainerStyle={containerStyle}
                              center={this.state.center}
                              zoom={this.state.zoom}
                              onBoundsChanged={this.boundsChanged}
                          >
                              <Markers events={this.state.markerArray} onEventClicked={this.handleEventClicked} selectedEvent={this.state.selectedEvent} />
                          </GoogleMap>
                      </LoadScript>
                      <QuickMap timeMaps={this.state.timeMaps} setCenter={this.changeCenter} setyear={this.updateYearRange} />
                  </Grid>
                  <Grid container style={{ width: "600px", height: "900px" }}
                      direction="row"
                      justify="flex-start"
                      sx={{  overflowY: 'auto' }}
                      alignItems="flex-start">
                      <HistoryEvent hevents={this.state.markerArray} selectedEvent={this.state.selectedEvent} onEventClicked={this.handleEventClicked} highlightedCardRef={this.highlightedCardRef} />
                  </Grid>
              </Grid>
              
              <div id="profileImage"></div>

          </div>
    );
    }

    static timer;

    boundsChanged(e) {
        var bounds = this._map.state.map.getBounds();
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();
        var ltlat = ne.lat();
        var rblat = sw.lat();
        var rblng = ne.lng();
        var ltlng = sw.lng();
        clearTimeout(this.timer)
        this.timer = setTimeout(() => { this.populateEventData(ltlat, ltlng, rblat, rblng, this.state.yearRange[0], this.state.yearRange[1]); }, 1000);
    }
}

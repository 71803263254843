import React, { useState } from 'react';

function ReportIssue({ type, uuid }) {
    const [notificationVisible, setNotificationVisible] = useState(false);

    const reportIssue = () => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/api/issue/report-issue', true);
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.onload = function () {
            if (xhr.status === 200) {
                showNotification();
            } else {
                alert('Failed to report issue.');
            }
        };
        xhr.onerror = function () {
            alert('Request error.');
        };
        const data = {
            type: type,
            uuid: uuid
        };
        xhr.send(JSON.stringify(data));
    };

    const showNotification = () => {
        setNotificationVisible(true);
        setTimeout(() => {
            setNotificationVisible(false);
        }, 3000); // Hide the notification after 3 seconds
    };

    return (
        <>
            <button className="report-button" onClick={reportIssue}>
                <span className="report-text">data has issue</span>
                <i className="fas fa-exclamation-circle"></i>
            </button>
            {notificationVisible && (
                <div className="notification">
                    Thank you! Your report has been sent.
                </div>
            )}
        </>
    );
}

export default ReportIssue;
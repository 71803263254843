import React, { Component, useState, useRef, useEffect} from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { PersonCard, PersonImage } from './PersonPage'
import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ReportIssue from './ReportIssue'


const containerStyle = {
    width: '1110px',
    height: '600px'
};

const formatDate = (year, month, day) => {
    var date = year.toString();
    if (month > 0 && month < 13) {
        date += "-" + month.toString();
    }
    if (day > 0 && day < 32) {
        date += "-" + day.toString();
    }
    return date;
}

export const HistoryEvent = ({ hevents, onEventClicked, selectedEvent, highlightedCardRef  }) => {
    return hevents.map(he => {
        if (selectedEvent != null && he.uuid == selectedEvent.uuid) {
            return <HistoryDetailEvent highlight={true} he={he} onEventClicked={onEventClicked} highlightedCardRef={highlightedCardRef} />;
        }
        return (
            <HistoryDetailEvent highlight={false} he={he} onEventClicked={onEventClicked} highlightedCardRef={highlightedCardRef} />
            //<HistoryBriefEvent he={he} onEventClicked={onEventClicked} />
        );
    });
}


export const HistoryBriefEvent = ({ he, onEventClicked }) => {
    const handleClick = () => {
        onEventClicked(he);
    };
    
    return (
        <div class="briefevent" onClick={handleClick}><div class="eventheader">
            <div calss="date">{formatDate(he.year, he.month, he.day)}</div>
            <div >{he.location}</div>
        </div> </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 350,
        marginTop: 4
    },
    leftAlignItem: {
        marginRight: "auto"
    },
    rightAlignItem: {
        marginLeft: "auto"
    },
    parentFlexLeft: {
        display: "flex",
        justifyContent: "flex-start"
    },
    parentFlexRight: {
        display: "flex",
        justifyContent: "flex-end"
    },
    parentFlexSplit: {
        display: "flex",
        justifyContent: "space-between"
    }
}));



export const HistoryDetailEvent = ({ highlight, he, onEventClicked, highlightedCardRef }) => {
    var linkUrl = "/events/" + he.uuid
    const handleClick = () => {
        onEventClicked(he);
    };
    const classes = useStyles();

    return (
        <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: highlight ? 'lightblue' : 'white', margin: 1 }}
            onClick={handleClick}
            variant={highlight ? 'solid' : 'outlined'}
            ref={highlight ? highlightedCardRef : null} >
            <CardContent>
                 <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {formatDate(he.year, he.month, he.day)}
                </Typography>
                 <Typography variant="h5" component="div">
                    {he.location}
                </Typography>
                <Typography variant="body2" sx={{overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",}}>
                    
                        {he.content}
                </Typography>
                
                <CardActions disableSpacing>
                    {he.people != null &&
                         he.people.map(p => (<PersonImage person={p} />)) 
                    }
                    <Button className={classes.rightAlignItem}
                        component={Link}
                        to={`/events/${he.uuid}`}
                        variant="contained"
                        color="primary"
                    >
                        View Details
                    </Button>
                    
                
                </CardActions>
            </CardContent>
        </Card>
    );
}

export class EventPage extends Component {
    constructor(props) {
        super(props);
        this.state = { historyEvent: null };
    }

    componentDidMount() {
        this.populateEventData();
        document.title = "Event Detail";
    }

    async populateEventData() {
        const response = await fetch(`api/historyevent/${this.props.match.params.uuid}`);
        const data = await response.json();
        this.setState({ historyEvent: data });
    }

    render() {
        if (this.state.historyEvent == null) {
            return (
                <div><h1>Loading</h1></div>
                );
        }

        var center = { lat: this.state.historyEvent.lat, lng: this.state.historyEvent.lng };

        return (
            <body>
                <header class="eventheader">
                    <h1>{formatDate(this.state.historyEvent.year, this.state.historyEvent.month, this.state.historyEvent.day)}</h1>
                    <ReportIssue type={1} uuid={this.state.historyEvent.uuid} />
                </header>
                <section class="event-details">
                    <h2>Event Description</h2>
                    <p>{this.state.historyEvent.content}</p>
                </section>
                <section class="map-container">
                    <h2>Event Location</h2>
                    <p>{this.state.historyEvent.location}</p>
                <LoadScript
                    googleMapsApiKey="AIzaSyC5A2D9YGo2XrLDTj9i2PegVo5qGTYDkeo"
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={10}
                    >
                        <Marker position={center} key={this.props.match.params.uuid} />
                    </GoogleMap>
                    </LoadScript>
                </section>

                {this.state.historyEvent.people != null &&

                    <section class="people-involved">
                        <h2>People Involved</h2>
                        {
                            this.state.historyEvent.people.map(person => {
                                return (
                                    <PersonCard person={person} />
                                )
                            })
                        }
                    </section>
                }
            </body>
            );
    }
}

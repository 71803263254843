import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@mui/material/Avatar';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import { HistoryEvent } from './EventPage'
import { Markers, EventPolyline } from './Marker'
import Grid from '@mui/material/Grid';
import './NavMenu.css';
import ReportIssue  from './ReportIssue'

const containerStyle = {
    height: '800px'
};
const formatYear = (year) => {
    if (year == 0) {
        return "Unknown";
    }
    return year;
};
export const PersonCard = ({ person }) => {
    const formatYear = (year) => {
        if (year == 0) {
            return "Unknown";
        }
        return year;
    };
    var linkUrl = "/people/" + person.uuid
    return (
        <div class="personcard">
            <div><b><Link to={linkUrl} >{person.name}</Link> ({formatYear(person.dobYear)} - {formatYear(person.dodYear)})</b></div>
            <div>{person.description}</div>
        </div>
        );
}

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: name.split(' ').length === 1 ? name[0] : `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}


export const PersonImage = ({ person }) => {
    return (
        <Tooltip
            title={person.name}>
            <Link to={`/people/${person.uuid}`} style={{ textDecoration: 'none' }}>
                <Avatar {...stringAvatar(person.name)} />
            </Link>
        </Tooltip>
    );
}

export class PersonPage extends Component {
    constructor(props) {
        super(props);
        this.state = { person: null, selectedEvent: null };
        this.handleEventClicked = this.handleEventClicked.bind(this);
        this.highlightedCardRef = React.createRef();
    }

    componentDidMount() {
        this.populatePeopleData();
    }

    handleEventClicked(hevent) {
        this.setState({ selectedEvent: hevent }, () => {
            if (this.highlightedCardRef.current) {
                this.highlightedCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        });
    }

    async populatePeopleData() {
        const response = await fetch(`api/people/${this.props.match.params.uuid}`);
        const data = await response.json();
        this.setState({ person: data });
        document.title = this.state.person.name;
    }

    render() {
        if (this.state.person == null) {
            return (
                <div><h1>Loading</h1></div>
            );
        }

        var center = { lat: this.state.person.events[0].lat, lng: this.state.person.events[0].lng };
        if (this.state.selectedEvent != null) {
            center = { lat: this.state.selectedEvent.lat, lng: this.state.selectedEvent.lng };
        }

        return (
            <body>
                <header class="eventheader">
                    <div class="eventheader-content">
                        <h1>{this.state.person.name}</h1>
                        <p class="date">{formatYear(this.state.person.dobYear)} - {formatYear(this.state.person.dodYear)}</p>
                    </div>
                    <ReportIssue type={2} uuid={this.state.person.uuid} />
                </header>
                <section class="event-details">
                    <h2>People Description</h2>
                    <p>{this.state.person.description}</p>
                </section>
                <section class="map-container">
                    <h2>People Events Location</h2>
                    <Grid container spacing={0} >
                        <Grid item xs>
                        <LoadScript
                            googleMapsApiKey="AIzaSyC5A2D9YGo2XrLDTj9i2PegVo5qGTYDkeo"
                        >
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={10}
                        >
                            <Markers events={this.state.person.events} onEventClicked={this.handleEventClicked} selectedEvent={this.state.selectedEvent} />
                            <EventPolyline events={this.state.person.events} />
                        </GoogleMap>
                            </LoadScript>
                   
                        </Grid>
                        <Grid container style={{ width: "600px", height: "750px" }}
                            direction="row"
                            justify="flex-start"
                            sx={{ overflowY: 'auto' }}
                            alignItems="flex-start">
                            <HistoryEvent hevents={this.state.person.events} selectedEvent={this.state.selectedEvent} onEventClicked={this.handleEventClicked} highlightedCardRef={this.highlightedCardRef} />
                        </Grid>
                  </Grid>
                 </section>
            </body>
            );
    }
        

}